/**
 * Created by LittleRedHat on 2016/8/1.
 */
var server_base ="";








var registerWithEmailRules = {
    username: {
        identifier: 'username',
        rules: [
            {
                type: 'empty',
                prompt: "请输入用户名"
            }
        ]
    },
    register_password: {
        identifier: 'register_password',
        rules: [
            {
                type: 'minLength[8]',
                prompt: "请输入至少8位的密码"

            },
            {
                type: 'maxLength[16]',
                prompt: "密码最多16位"

            },
            {
                type: 'regExp[/[0-9]+/]',
                prompt: "密码必须至少包含一个数字"
            },
            {
                type: 'regExp[/[a-z]+/]',
                prompt: "密码必须至少包含一个小写字母"
            },
            {
                type: 'regExp[/[A-Z]+/]',
                prompt: "密码必须至少包含一个大写字母"
            }
        ]
    },
    password_review: {
        identifier: 'password_review',
        rules: [
            {
                type: 'empty',
                prompt: "请再次输入密码"
            },

            {
                type: 'match[register_password]',
                prompt: '密码输入不一致'
            }

        ]

    },
    email: {
        identifier: 'email',
        rules: [
            {
                type: 'email',
                prompt: "请输入合法的邮箱地址"
            }
        ]
    },
    code:{
        rules:[
            {
                type:'empty',
                prompt:"请输入验证码"
            }
        ]
    }
};
var registerWithPhoneRules = {
    username: {
        identifier: 'username',
        rules: [
            {
                type: 'empty',
                prompt: "请输入用户名"
            }
        ]
    },
    register_password: {
        identifier: 'register_password',
        rules: [
            {
                type: 'minLength[8]',
                prompt: "请输入至少8位的密码"

            },
            {
                type: 'maxLength[16]',
                prompt: "密码最多16位"

            },
            {
                type: 'regExp[/[0-9]+/]',
                prompt: "密码必须至少包含一个数字"
            },
            {
                type: 'regExp[/[a-z]+/]',
                prompt: "密码必须至少包含一个小写字母"
            },
            {
                type: 'regExp[/[A-Z]+/]',
                prompt: "密码必须至少包含一个大写字母"
            }
        ]
    },
    password_review: {
        identifier: 'password_review',
        rules: [
            {
                type: 'empty',
                prompt: "请再次输入密码"
            },

            {
                type: 'match[register_password]',
                prompt: '密码输入不一致'
            }

        ]

    },
    phone: {
        identifier: 'phone',
        rules: [
            {
                type: 'regExp[/^[0-9]{11}$/]',
                prompt: "请输入11位手机号"
            }
        ]
    },
    code:{
        rules:[
            {
                type:'empty',
                prompt:"请输入验证码"
            }
        ]
    }
};
var loginRules = {
    username: {
        identifier: 'username',
        rules: [
            {
                type: 'regExp[/(^[0-9]{11}$)|(^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$)/]',
                prompt: "请输入手机或者邮箱"
            }
        ]
    },
    login_password:{
        identifier:'login_password',
        rules:[
            {
                type: 'empty',
                prompt: "请输入密码"

            }
        ]
    }
};
var registerWith = 0;// 0 for phone 1 for email
var valid_timer;
var reg_wait = false;
var reset_pass_wait = false;
var resetPassRules = {
    reset_pass_username: {
        identifier: 'reset_pass_username',
        rules: [
            {
                type: 'regExp[/(^[0-9]{11}$)|(^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$)/]',
                prompt: "请输入手机或者邮箱"
            }
        ]
    },
    reset_pass_code:{
        identifier:'reset_pass_code',
        rules:[
            {
                type: 'empty',
                prompt: "请输入验证码"

            }
        ]
    },
    reset_pass: {
        identifier: 'reset_pass',
        rules: [
            {
                type: 'minLength[8]',
                prompt: "请输入至少8位的密码"

            },
            {
                type: 'maxLength[16]',
                prompt: "密码最多16位"

            },
            {
                type: 'regExp[/[0-9]+/]',
                prompt: "密码必须至少包含一个数字"
            },
            {
                type: 'regExp[/[a-z]+/]',
                prompt: "密码必须至少包含一个小写字母"
            },
            {
                type: 'regExp[/[A-Z]+/]',
                prompt: "密码必须至少包含一个大写字母"
            }
        ]
    },
    reset_pass_review: {
        identifier: 'reset_pass_review',
        rules: [
            {
                type: 'empty',
                prompt: "请再次输入密码"
            },

            {
                type: 'match[reset_pass]',
                prompt: '密码输入不一致'
            }

        ]

    }


};
function show_reset_pass(){
    $('.ui.modal.reset-pass-modal')
        .modal('show');

}
function show_login(){
    $('.ui.modal.login-modal')
        .modal('show');
}
function show_register(){
    $('.ui.modal.register-modal').modal('show');
}
function register() {
    if(registerWith == 0){
        $('.ui.form.register-form').form({
            fields:registerWithPhoneRules,
            keyboardShortcuts:false,
            inline: true,
            on: 'submit'
        });
        var valid = $('.ui.form.register-form').form('is valid', registerWithPhoneRules);

        if(valid) {
            var username = $('.ui.form.register-form input[name=username]').val();
            var password = $('.ui.form.register-form input[name=register_password]').val();
            var phone = $('.ui.form.register-form input[name=phone]').val();
            var code =$('.ui.form.register-form input[name=code]').val();
            $.ajax({
                url: server_base + '/user/register/phone',
                type: 'POST',
                data: {
                    username: username,
                    password: password,
                    phone: phone,
                    code: code
                },
                dataType: 'json',
                xhrFields: {withCredentials: true},
                crossDomain: true,

                success: function (data) {
                    //window.location.href = "login.html";
                    if (data.status == 0) {
                        $('.ui.modal.login-modal').modal('hide');
                        sessionStorage.setItem("user", JSON.stringify(data.user));
                        $('.ui.modal.register-modal').modal('hide');
                        navbar_reset();

                    } else {
                        $('#warning').find('.content').text(data.message);
                        $('#warning').modal('show');
                    }
                }
            });
        }

    }else{
        $('.ui.form.register-form').form({
            fields:registerWithEmailRules,
            keyboardShortcuts:false,
            inline: true,
            on: 'submit'
        });
        var valid = $('.ui.form.register-form').form('is valid', registerWithEmailRules);
        if(valid){
            var username = $('.ui.form.register-form input[name=username]').val();
            var password = $('.ui.form.register-form input[name=register_password]').val();
            var email = $('.ui.form.register-form input[name=email]').val();
            var code =$('.ui.form.register-form input[name=code]').val();
            console.log({
                username: username,
                password: password,
                email: email,
                code: code
            });
            $.ajax({
                url: server_base + '/user/register/email',
                type: 'POST',
                data: {
                    username: username,
                    password: password,
                    email: email,
                    code: code
                },
                dataType: 'json',
                xhrFields: {withCredentials: true},
                crossDomain: true,

                success: function (data) {
                    //window.location.href = "login.html";
                    if (data.status == 0) {
                        $('.ui.modal.login-modal').modal('hide');
                        sessionStorage.setItem("user", JSON.stringify(data.user));
                        $('.ui.modal.register-modal').modal('hide');


                        navbar_reset();

                    } else {
                        $('#warning').find('.content').text(data.message);
                        $('#warning').modal('show');
                    }
                }
            });
        }

    }

    return false;

}
function login(){
    var valid = $('.ui.form.login-form').form('is valid', loginRules);
    // //console.log(valid);
    if(valid){
        var username = $('.ui.form.login-form input[name=username]').val();
        var password = $('.ui.form.login-form input[name=login_password]').val();
        $.ajax({
            url: server_base+'/user/login',
            type: 'POST',
            data: {
                username: username,
                password: password

            },
            xhrFields: {withCredentials: true},
            //crossDomain:true,
            success: function (data) {
                if(data.status == 0){
                    // //console.log(data);
                    sessionStorage.setItem("user",JSON.stringify(data.user));
                    $('.ui.modal.login-modal').modal('hide');
                    navbar_reset();

                }else{
                    $('#warning').find('.content').text(data.message);
                    $('#warning').modal('show');

                }
            }
        })



    }
    return false;

}
function logout(){
    $.ajax({
        url:server_base+"/user/logout",
        method:'POST',
        dataType:'json',
        success:function(data){
            if(data.status==0){
                if(sessionStorage.getItem("user")){
                    sessionStorage.removeItem("user");
                    window.location.href="index.html";
                }
            }
        }
    })
}
function navbar_reset(){
    if(sessionStorage.getItem('user')){
        $('#top-right').html(S['templates']['navbar_user'](JSON.parse(sessionStorage.getItem('user'))));
        // $('.ui.dropdown.user-item').dropdown({
        //     on: 'hover',
        //     transition: 'drop'
        // });

    }else{
        $('#top-right').html(S['templates']['navbar_nouser']({}));
    }
    // $('.client-item')
    //     .popup({
    //         popup : $('.popup.qrcode'),
    //         on    : 'hover',
    //         position:'bottom left',
    //         inline:true
    //     });
    // $('.post-item')
    //     .popup({
    //         popup:$('.popup.link'),
    //         on   :'hover',
    //         position:'bottom left',
    //         inline:true
    //
    //     });
}
var subject_color = [
    'red','blue','black','purple','default','yellow','pink','green','brown','orange'
];
function getSymbols(){
    if(sessionStorage.getItem('symbols')){
        var subjects_data = JSON.parse(sessionStorage.getItem("symbols"));
        // //console.log(subjects_data);
        $('#subjects').html(S["templates"]["subjects"](subjects_data));
        return;
    }
    /*
     get symbols
     */
    $.ajax({
        url:server_base+'/subject/get',
        method:'GET',
        dataType:"json",
        success:function(data){
            var subjects_data = data;
            // //console.log("get subjects "+ JSON.stringify(data));
            $.each(subjects_data.subjects,function(index,value){
                value.color = subject_color[index % subjects_data.subjects.length];
            });
            $('#subjects').html(S["templates"]["subjects"](subjects_data));
            sessionStorage.setItem("symbols",JSON.stringify(subjects_data));
        }
    });
}
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null)
        return RegExp.$2;
    return null; //返回参数值
}
function goSubject(subject){
    window.location.href="start.html?symbol="+subject;
}
function goDebut(type){
    window.location.href="debut.html?type="+type;
}
function browserDetect(){




    var flag = true

    if(browser.rv){
        flag = false
        alert("请使用Chrome或者Firefox打开，搜狗以及360浏览器请使用极速模式")
    }else if(browser.Safari && browser.Safari <= "7"){
        flag = false
        alert("Safari版本过低请使用最新版本的浏览器")
    }else if(browser.Chrome && browser.Chrome <= "35"){
        flag = false
        alert("Chrome版本过低请使用最新版本的浏览器")

    }
    return flag





}

$(document).ready(function() {
    if(!browserDetect()){
        // window.location.href = "404.html"
    }
    getSymbols();
    navbar_reset();
    $('.ui.modal').modal({
        onHide:function(){
            $(this).form('reset');
        }
    });
    $('.ui.form.login-form').form({
        keyboardShortcuts:false,
        fields: loginRules,
        inline: true,
        on: 'submit'
    });
    $('.ui.form.reset-pass-form').form({
        keyboardShortcuts:false,
        fields: resetPassRules,
        inline: true,
        on: 'submit'
    });

    $('.navbar').visibility({
        type: 'fixed'
    });
    // show dropdown on hover
    $('.navbar .ui.dropdown').dropdown({
        on: 'hover',
        transition: 'drop'
    });
    $('.menu .item').tab();
    $(window).scroll(function(event) {

        if($(window).scrollTop()>100){
            // ////console.log('scroll bottom');
            if($('#gotop').is(":hidden")){
                $('#gotop').show();
            }
        }else{
            if($('#gotop').is(":visible")){
                $('#gotop').hide();
            }

        }

    });

});

function goTop(){
    $('body,html').stop().animate({scrollTop:0},500);
}
function  search(){
    var keyword = $('.top-search input[name=keyword]').val();
    if(keyword){
        window.location.href = "search_total.html?keyword="+encodeURIComponent(keyword);
    }
}
function searchKeyDown(event){
    var k = event.keyCode || event.which
    if(k == 13){
        search()
    }

}


function valid_email(){
    $('.phone-field').hide();
    $('.email-field').show();
    registerWith = 1;


}
function valid_phone(){
    $('.email-field').hide();
    $('.phone-field').show();
    registerWith = 0;
}

function regCodeBackCount(){
    var count  = 60;
    reg_wait = true;
    valid_timer = setInterval(function(){
        if(count >= 0){
            $('.get-register-code').html("获取验证码("+count+"s)");
            count = count -  1;

        }else{
            reg_wait = false;
            $('.get-register-code').html("获取验证码");
            clearInterval(valid_timer);

        }

    },1000);

}
function resetCodeBackCount(){
    var count  = 60;
    reset_pass_wait = true;
    valid_timer = setInterval(function(){
        if(count >= 0){
            $('.get-reset-pass-code').html("获取验证码("+count+"s)");
            count = count - 1;

        }else{
            reset_pass_wait = false;
            $('.get-reset-pass-code').html("获取验证码");
            clearInterval(valid_timer);

        }

    },1000);
}
function getRegCode(){
    if(reg_wait){
        return;
    }
    if(registerWith == 0){
        var phone = $('.ui.form.register-form').form('get value','phone');
        $.ajax({
            url:server_base+'/user/sendCode/phone',
            method:'POST',
            data:{
                phone:phone
            },
            xhrFields: {withCredentials: true},
            crossDomain:true,
            success:function(data){
                if(data.status == 0){
                    regCodeBackCount();
                } else {
                    $('#warning').find('.content').text(data.message);
                    $('#warning').modal('show');
                }





            }
        });
    }else{
        var email = $('.ui.form.register-form').form('get value','email');
        $.ajax({
            url:server_base+'/user/sendCode/email',
            method:'POST',
            data:{
                email:email
            },
            xhrFields: {withCredentials: true},
            crossDomain:true,
            success:function(data){
                if(data.status == 0){
                    regCodeBackCount();
                }



            }
        });

    }

}

function getResetPassCode(){
    if(reset_pass_wait){
        return;
    }

    var username = $('.ui.form.reset-pass-form').form('get value',"reset_pass_username");
    $.ajax({
        url:server_base+'/user/sendCode/password',
        method:'POST',
        data:{
            username:username
        },
        //xhrFields: {withCredentials: true},
        //crossDomain:true,
        success:function(data){
            if(data.status == 0){
                resetCodeBackCount();
            } else {
                $('#warning').find('.content').text(data.message);
                $('#warning').modal('show');
            }
        }
    });

}


function resetPass(){
    var valid = $('.ui.form.reset-pass-form').form('is valid',resetPassRules);
    if(valid){
        var password = $('.ui.form.reset-pass-form').form('get value','reset_pass_code');
        var code = $('.ui.form.reset-pass-form').form('get value','reset_pass');
        var username = $('.ui.form.reset-pass-form').form('get value','reset_pass_username');
        $.ajax({
            url:server_base+'/user/sendCode/password',
            method:'POST',
            data:{
                username:username,
                password:password,
                code:code
            },
            //xhrFields: {withCredentials: true},
            //crossDomain:true,
            success:function(data){
                if(data.status == 0){
                    $('#warning').find('.content').text("密码重置成功");
                    $('#warning').modal('show');

                } else {
                    $('#warning').find('.content').text(data.message);
                    $('#warning').modal('show');
                }
            }
        });



    }
}